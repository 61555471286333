import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import { TbFoldersOff } from "react-icons/tb";
import { IGetStaticProps } from "../interfaces/next/IGetStaticProps";
import Link from "next/link";
import { MaterialLayout } from "../components/global/layout/material/MaterialLayout";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
export default function Custom400() {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  return (
    <MaterialLayout>
      <Head>
        <title>Page Not Found | Pod1um</title>
        <meta name="dc.title" content="Page Not Found | Pod1um" />
        <meta name="og:title" content="Page Not Found | Pod1um" />
        <meta name="twitter:title" content="Page Not Found | Pod1um" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="flex items-center justify-center rounded-full p-8 bg-gray-100">
          <TbFoldersOff className="text-9xl text-gray-400" />
        </div>

        <div className="mt-10 lg:mt-16 space-y-4 lg:space-y-8 text-center w-3/4 lg:w-2/4">
          <h3 className="text-2xl font-semibold  lg:text-4xl">
            We can’t find the page you’re looking for
          </h3>

          <h4 className="text-xl font-normal lg:text-2xl">
            Either this page is currently set to private, or it does not exist
          </h4>
          <p className="lg:text-lg">
            Try searching for{" "}
            <Link
              style={{
                color: componentColors.primary.primary,
              }}
              className="underline"
              href="/specialists"
            >
              Coaches
            </Link>
            ,{" "}
            <Link
              style={{
                color: componentColors.primary.primary,
              }}
              className="underline"
              href="/training-plans"
            >
              Training plans
            </Link>
            , or{" "}
            <Link
              style={{
                color: componentColors.primary.primary,
              }}
              className="underline"
              href="/workouts"
            >
              Workouts
            </Link>
          </p>
        </div>
      </div>
    </MaterialLayout>
  );
}

export async function getStaticProps({
  locale,
}: {
  locale: string;
}): Promise<IGetStaticProps> {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "menu", "footer"])),
      // allBlogs
    },
  };
}
